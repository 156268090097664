const GoogleAnalytics = class {

  /*
  |--------------------------------------------------------------------------
  | Interface
  |--------------------------------------------------------------------------
  */

  /**
   */
  init (url, id) {
    log('init google analytics: not implemented yet')
  }

  /**
   */
  track (path, referrer) {
    log('track google analytics: not implemented yet')
  }
}

export default GoogleAnalytics